.header-navbar {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: red;
}

.header-navbar .header-brand {
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
  font-weight: 800;
  letter-spacing: -1px;
  color: var(--score-card-theme-text-color);
}

.header-navbar .header-version {
  font-size: 10px;
  font-family: 'Roboto Condensed';
  font-weight: 400;
  letter-spacing: -1px;
}

/* .header-navbar .nav-link {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
}
 */
.navbar-light .navbar-nav .nav-link {
  color: var(--score-card-theme-text-color);
}

.navbar-light .navbar-nav .nav-link:hover {
  opacity: 0.6;
}

.app-title {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Roboto Condensed';
  text-transform: uppercase;
  padding: 0px;
  margin: 0px;
}
