.home-page {
  padding: 0;
  margin: -8px -16px 0px -16px;
}

.home-page .home-page-carousel-image {
  height: 85vh;
  object-fit: cover;
}

.home-page .main-motivator {
  font-size: 48px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: 'Roboto Condensed';
}

.home-page .main-motivator-shadow {
  font-size: 96px;
  line-height: 96px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: 'Roboto Condensed';
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.home-page .main-subtitle {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.home-page .section-title {
  font-size: 32px;
  line-height: 44px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Roboto Condensed';
  margin-bottom: 16px;
}

.home-page section {
  padding: 16px 16px 32px 16px;
}

.home-page section .message {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
}

.home-page section .message img {
  border-radius: 8px;
}

@media (min-width: 1024px) {
  .home-page .main-motivator {
    font-size: 104px;
    line-height: 64px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: 'Roboto Condensed';
  }

  .home-page .main-subtitle {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .home-page section {
    margin-top: 96px;
    margin-bottom: 96px;
  }

  .home-page section .message {
    width: 75%;
  }
}
