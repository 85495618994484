.score-legend {
  font-size: 12px;
}

.score-legend .score-handle {
  width: 24px;
  height: 24px;
  border: 1px solid lightgray;
}

/* --------------------------------------------- */

.score-legend .score-color .hole-shot-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  width: var(--score-card-grid-cell-size);
  height: var(--score-card-grid-cell-size);
  padding: 0px;
  margin: 0px;
  border: 2px solid white;
}

.score-legend .score-color .hole-shot-retired {
  color: gray;
  font-size: 3em;
}

.score-legend .score-color .hole-shot-albatros {
  border-radius: 50%;
  color: black;
  background-color: yellow;
}

.score-legend .score-color .hole-shot-eagle {
  border-radius: 50%;
  color: black;
  background-color: #fecd31;
}

.score-legend .score-color .hole-shot-birdie {
  border-radius: 50%;
  color: white;
  background-color: #ff1900;
}

.score-legend .score-color .hole-shot-par {
  color: black;
  background-color: lightgray;
}

.score-legend .score-color .hole-shot-bogey {
  background-color: #7ca9cf;
  color: white;
}

.score-legend .score-color .hole-shot-doble-bogey {
  background-color: #005091;
  color: white;
}

.score-legend .score-color .hole-shot-triple-bogey {
  background-color: #7ca9cf;
  color: white;
}

/* --------------------------------------------- */
:root {
  --score-eagle: 2px;
  --score-albatros: 5px;
}

.score-legend .score-geometric .hole-shot-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  width: 36px;
  height: 36px;
  color: black;
  background-color: white;
  padding: 0px;
  margin: 4px;
}

.score-legend .score-geometric .hole-shot-retired {
  color: gray;
  font-size: 3em;
}

.score-legend .score-geometric .hole-shot-birdie {
  border-radius: 50%;
  border: 1px solid black;
  color: black;
}

.score-legend .score-geometric .hole-shot-eagle {
  position: relative;
  border: 1px solid black;
  border-radius: 50%;
}

.score-legend .score-geometric .hole-shot-eagle::after {
  position: absolute;
  border: 1px solid black;
  border-radius: 50%;
  content: '';
  top: var(--score-eagle);
  left: var(--score-eagle);
  right: var(--score-eagle);
  bottom: var(--score-eagle);
}

.score-legend .score-geometric .hole-shot-albatros {
  position: relative;
  border: 1px solid black;
  border-radius: 50%;
}

.score-legend .score-geometric .hole-shot-albatros::before {
  position: absolute;
  border: 1px solid black;
  border-radius: 50%;
  content: '';
  top: var(--score-eagle);
  left: var(--score-eagle);
  right: var(--score-eagle);
  bottom: var(--score-eagle);
}

.score-legend .score-geometric .hole-shot-albatros::after {
  position: absolute;
  border: 1px solid black;
  border-radius: 50%;
  content: '';
  top: var(--score-albatros);
  left: var(--score-albatros);
  right: var(--score-albatros);
  bottom: var(--score-albatros);
}

.score-legend .score-geometric .hole-shot-bogey {
  border: 1px solid black;
}

.score-legend .score-geometric .hole-shot-doble-bogey {
  position: relative;
  border: 1px solid black;
}

.score-legend .score-geometric .hole-shot-doble-bogey::after {
  position: absolute;
  border: 1px solid black;
  content: '';
  top: var(--score-double-border);
  left: var(--score-double-border);
  right: var(--score-double-border);
  bottom: var(--score-double-border);
}

/* --------------------------------------------- */

.score-legend .score-the-open .hole-shot-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  width: var(--score-card-grid-cell-size);
  height: var(--score-card-grid-cell-size);
  padding: 0px;
  margin: 0px;
  background-color: white;
  color: black;
  border: 2px solid white;
}

.score-legend .score-the-open .hole-shot-retired {
  color: gray;
  font-size: 3em;
}

.score-legend .score-the-open .hole-shot-albatros {
  color: white;
  background-color: #600131;
}

.score-legend .score-the-open .hole-shot-eagle {
  color: white;
  background-color: #910149;
}

.score-legend .score-the-open .hole-shot-birdie {
  color: white;
  background-color: #d4062d;
}

.score-legend .score-the-open .hole-shot-par {
  color: white;
  background-color: #2a69a3;
}

.score-legend .score-the-open .hole-shot-bogey {
  color: black;
  background-color: #d3d3d3;
}

.score-legend .score-the-open .hole-shot-doble-bogey {
  background-color: gray;
  color: white;
}

.score-legend .score-the-open .hole-shot-triple-bogey {
  background-color: darkgray;
  color: white;
}

.score-legend .score-the-open .hole-shot-retired {
  color: gray;
  font-size: 3em;
}

/* --------------------------------------------- */

.score-legend .score-dp-world-tour .hole-shot-number {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  width: var(--score-card-grid-cell-size);
  height: var(--score-card-grid-cell-size);
  padding: 0px;
  margin: 0px;
  background-color: white;
  color: black;
  border: 2px solid white;
}

.score-legend .score-dp-world-tour .hole-shot-retired {
  color: gray;
  font-size: 3em;
}

.score-legend .score-dp-world-tour .hole-shot-albatros {
  color: white;
  background-color: #ffb81c;
}

.score-legend .score-dp-world-tour .hole-shot-eagle {
  color: white;
  background-color: #ffb81c;
}

.score-legend .score-dp-world-tour .hole-shot-birdie {
  color: white;
  background-color: #dc1a21;
}

.score-legend .score-dp-world-tour .hole-shot-par {
  color: black;
  background-color: white;
}

.score-legend .score-dp-world-tour .hole-shot-bogey {
  color: white;
  background-color: #5ea5f7;
}

.score-legend .score-dp-world-tour .hole-shot-doble-bogey {
  color: white;
  background-color: #0964cb;
}

.score-legend .score-dp-world-tour .hole-shot-triple-bogey {
  color: white;
  background-color: #0964cb;
}

.score-legend .score-dp-world-tour .hole-shot-retired {
  color: gray;
  font-size: 3em;
}

/* --------------------------------------------- */

.match-score {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 56px;
  padding: 4px 8px;
  margin: 4px;
  font-size: 20px;
  font-weight: 800;
}

.match-score-under {
  /*   background-color: red;
  color: white;
 */
  color: red;
}

.match-score-over {
  color: black;
}

.match-score-par {
  color: green;
}
