@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Roboto Condensed:400,500,600,700,800,900');

:root {
  /*
  --score-card-theme-text-color: rgb(94, 94, 165);
  --score-card-theme-text-color: #5581c3;
  */
  --score-card-theme-text-color: var(--bs-primary);
  --score-card-theme-background-color: var(--score-card-theme-text-color);
  --score-card-theme-color: white;
  --score-card-theme-hover: 0, 0, 0;
  --score-card-theme-topbar-background-color: white;
  --score-card-theme-topbar-text-color: black;
  --score-card-theme-button-color: black;
  --score-card-theme-button-background-color: #f7f7f7;
  --score-card-theme-button-primary-color: black;
  --score-card-theme-button-primary-background-color: #e0e0e0;
  --score-card-theme-button-hover-background-color: #e0e0e0;
  --score-card-theme-button-hover-color: black;
  /*   --score-card-theme-table-header-background-color: var(--score-card-theme-background-color);
  --score-card-theme-table-header-color: var(--score-card-theme-color);
 */
  --score-card-theme-table-header-background-color: transparent;
  --score-card-theme-table-header-color: black;
  --score-card-theme-table-total-background-color: var(--score-card-theme-background-color);
  --score-card-theme-table-total-color: white;
  --score-card-theme-table-inout-background-color: rgb(240, 240, 247);
  --score-card-theme-table-inout-color: black;

  --score-card-grid-cell-size: 42px;
  --score-double-eagle: 4px;
  --score-double-border: 4px;
  --score-width: 48px;
  --score-height: 48px;
}

html,
body {
  margin: 0;
  font-family: 'Roboto', 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(228, 228, 228); */
  background-color: var(--score-card-theme-color);
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

table {
  font-size: 14px;
}

table thead tr,
table thead th,
table tbody tr.title-row {
  background-color: var(--score-card-theme-table-header-background-color);
  color: var(--score-card-theme-table-header-color);
  padding: 8px 8px;
}

table thead th {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

tr:hover {
  cursor: pointer;
}

.navigate-hover:hover {
  cursor: pointer;
  color: rgb(64, 64, 64);
  font-weight: 500;
}

.navigate-hover {
  color: darkblue;
  font-weight: 500;
}

table tbody td.total-column {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--score-card-theme-table-inout-background-color);
}

.table {
  margin-bottom: unset;
}

td {
  vertical-align: middle;
  text-align: center;
}

.page-title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
}

.page-title-counter {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Roboto Condensed';
}

.text-theme {
  color: var(--score-card-theme-text-color);
}

.card {
  border: unset;
  background-color: white;
  border-radius: 16px;
}

@media only screen and (max-width: 600px) {
  .card {
    border-radius: 0px;
  }
}

.list-group-item {
  border: unset;
}

.btn {
  font-family: 'Roboto Condensed';
  text-transform: uppercase;
  font-weight: 400;
  /* background-color: var(--score-card-theme-button-background-color); */
}

.btn-primary {
  font-family: 'Roboto Condensed';
  text-transform: uppercase;
  font-weight: 400;
  /* color: var(--score-card-theme-button-primary-color);
  background-color: var(--score-card-theme-button-primary-background-color);
  border: 1px solid transparent; */
}

.btn:hover {
  background-color: var(--score-card-theme-button-hover-background-color);
  color: var(--score-card-theme-button-hover-color);
}

.btn-roboto {
  font-family: 'Roboto';
  text-transform: uppercase;
}

.nav-link {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Roboto Condensed';
}

.data-label {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

.data-content {
  font-size: 28px;
  text-align: center;
}

.score-card-info-label {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Roboto Condensed';
  text-transform: uppercase;
}

.score-card-info-data {
  font-size: 28px;
  font-weight: 400;
  font-family: 'Roboto Condensed';
}

.score-card-info-symbol {
  font-size: 18px;
  font-family: 'Roboto Condensed';
}

.score-card-info-separator {
  border-right: 1px solid lightgray;
  height: 64px;
  width: 1px;
}

.avatar-img {
  border-radius: 50%;
}

@import 'index-score-legend.css';
